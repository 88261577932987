import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { PromoSection } from '../common/promo_section';

export const Introduction: React.FC = () => {
  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      promo: file(relativePath: {eq: "promo/vision.jpg"}) { ...promoImage } 
    }`
  );

  return (
      <PromoSection>
        <div className="page-promo-text">
          <h1>{t('vision.introduction.heading')}</h1>
          <p>{t('vision.introduction.description-01')}</p>
          <p>{t('vision.introduction.description-02')}</p>
        </div>
        <div className="page-promo-image">
          <div className="image-holder">
            <div className="image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.promo.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </PromoSection>
  );
};
